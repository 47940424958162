@import "./sass-variables.sass"

.projectDisactive
    position: fixed
    opacity: 0
    transition: 0.5s
    z-index: -100000
.projectActive
    position: fixed
    top: 0
    left: 0
    opacity: 1
    display: grid
    place-items: center
    width: 100vw
    height: 100vh
    background-color: rgba(67,5,200, 0.8)
    transition: 0.5s
    z-index: 100000

    #projectContainer
        width: 100%
        display: flex
        margin-top: 50px
        padding-top: 50px
        padding-bottom: 50px
        justify-content: center
        place-items: center
        #activeBtnText
            #activeText
                box-sizing: border-box
                width: 420px
                margin-right: -25px
                margin-bottom: -25px
                padding: 20px
                padding-bottom: 40px
                background: $clight
                box-shadow: 0px 0px 30px 5px rgba(#222222,0.75)
                color: rgb(71,6,145)
                text-align: left
                transition: 0.5s
                z-index: 2
                h1
                    margin-bottom: 0px
                    margin-left: -5px
                    font-size: 64px
                    font-weight: 900
                    line-height: 64px
                    min-width: 400px
                p
                    margin-right: 0px
                    margin-bottom: 0px
                    margin-left: 0px
                    padding: 0px
                    padding-right: 0px
                    color: $cfour
                    font-size: 17px
                    font-weight: 500
                    line-height: 40px
                ul
                    margin-left: 40px
                    li
                        margin-bottom: 5px
                        font-size: 20px
                        text-transform: uppercase

            a
                button
                    top: 0px
                    left: 0px
                    width: 280px
                    height: 85px
                    margin: 0px
                    margin-top: -20px
                    background-image: linear-gradient(to right, rgb(67,5,200), darkmagenta)
                    border: 1px solid $cthird
                    border-radius: 50px
                    color: $clight
                    font-size: 40px
                    text-transform: lowercase
                    z-index: 3
                    &:hover
                        background-image: linear-gradient(to left, rgb(67,5,200), darkmagenta)
            #projectEscape
                position: absolute
                display: grid
                place-items: center
                top: -135px
                left: 985px
                transform: rotate(45deg)
                color: $clight
                font-size: 70px
                font-weight: 900
                cursor: pointer
                z-index: 100
                transition: 1s
                // width: 30px
                // height: 30px

                &:hover
                    color: $cthird
            .escapeBtnGo
                transform: translateX(1000px)
                transition: 1s

        #activeImg
            position: relative
            overflow: hidden
            width: 600px
            height: 600px
            margin-left: -25px
            box-shadow: 10px 10px 30px 5px rgba(#000000,0.75)
            z-index: 1
            img
                position: relative
                top: 0
                left: 10%
                transform: translateX(-12%) translateY(0%)  scale(1.1)
                z-index: 1

@media(max-width: 1200px)
    .projectActive
        #projectContainer
            margin-top: 0
            #activeBtnText
                #projectEscape
                    top: -60px
                    left: 815px
            #activeImg
                width: 450px
                height: 450px
                overflow: hidden
                img
                    transform: scale(0.8) translateX(-25%) translateY(-15%)
                    left: 0
            button
                width: 200px

@media(max-width: 900px)
    .projectActive
        #projectContainer
            flex-direction: column
            align-items: center
            padding: 10px 0px
            #activeBtnText
                margin: 0
                #activeText
                    width: 520px
                    text-align: center
                    margin: 0px
                    flex-direction: column
                    align-items: center
                    h1
                        margin-bottom: 0px
                    ul
                        display: flex
                        flex-wrap: wrap
                        text-align: left
                        justify-content: center
                        margin-left: 40px
                        margin-bottom: 0px

                        li
                            width: 40%
                            margin: 10px
                #projectEscape
                    top: -20px
                    left: 470px
                    color: rgb(71,6,145)

            #activeImg
                width: 520px
                height: 450px
                margin: 0px
                margin-top: -25px
                overflow: hidden
                img
            button
                margin: 0px

@media(max-width: 600px)
    .projectActive
        margin-top: 15px
        #projectContainer
            height: 85%
            #activeBtnText
                #activeText
                    width: 280px
                    text-align: center
                    margin: 0
                    display: flex
                    flex-direction: column
                    align-items: center
                    h1
                        margin-bottom: 10px
                        font-size: 50px
                        line-height: 50px
                        min-width: 0
                    p
                        text-align: left
                        width: 100%
                        line-height: 24px
                    ul
                        display: flex
                        flex-wrap: wrap
                        text-align: left
                        justify-content: center
                        width: 100%
                        margin-left: 0px
                        margin: 0px

                        li
                            margin: 10px
                            margin-bottom: 0px
                            font-size: 16px
                a
                    button
                        width: 200px
                        height: 75px
                        margin-top: -35px
                        font-size: 35px
                #projectEscape
                    top: -20px
                    left: 252px
                    font-size: 50px
                    color: rgb(71,6,145)
            #activeImg
                width: 280px
                height: 225px
                overflow: hidden
                margin-bottom: 10px
                img
                    transform: scale(0.5) translateX(-60%) translateY(-60%)
