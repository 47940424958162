@import "./sass-variables.sass"

$footer-height: 400px
$small-footer-height: 400px

#footer
    box-sizing: border-box
    // min-height: 400px
    height: $footer-height
    background: $lin2
    background-size: $bs1
    background-position: center
    color: $clight
    display: flex
    justify-content: center
    align-items: center
    font-size: 15px
    overflow: hidden
    #mainFooter
        display: flex
        width: 100%
        height: $footer-height
        margin: auto
        justify-content: center
        #footerPhoto
            width: 440px
            img
                position: absolute
                left: 50%
                top: 50%
                transform: translateX(-63%) translateY(-48%)
                height: calc($footer-height * 1.08)
                width: auto
        #footerCopyrightsContainer
            display: flex
            flex-direction: column
            #contactSocialsContainer
                display: flex
                #contactFootContainer
                    min-width: 225px
                    h1
                        font-size: 36px
                        padding-top: 90px
                        padding-bottom: 30px
                        margin: 0
                    p
                        margin-bottom: 20px
                        .icon
                            width: 25px
                            margin-right: 10px
                        a
                            text-decoration: none
                            color: $clight
                            margin: 0
                            &:hover
                                color: $cthird
                #meSocialsContainer
                    display: flex
                    height: 300px
                    display: flex
                    align-items: center
                    margin-left: 0px
                    transform: translateX(120px)
                    .navIcon
                        width: 50px
                        height: 50px
                        margin: 0
                        padding: 0
                        margin-left: 45px
                        a
                            color: $clight
                            &:hover
                                color: $cthird
                            .icon
                                margin: 0
                                padding: 0

            #copyrights
                height: 50px
                display: flex
                p
                    width: 100%
                    max-width: $maxWidth
                    color: $cthird

@media(max-width: 1200px)
    #footer
        height: 350px
        #mainFooter
            #footerCopyrightsContainer
                #contactSocialsContainer
                    #meSocialsContainer
                        margin-top: 100px
                        flex-direction: column
                        height: 250px
                        transform: translateX(0px)
                        .navIcon
                            margin-left: 45px
                            margin-top: 20px

@media(max-width: 900px)
    #footer
        #mainFooter
            #footerCopyrightsContainer
                #contactSocialsContainer
                    flex-direction: column
                    #meSocialsContainer
                        margin-top: 20px
                        margin-bottom: 30px
                        flex-direction: row
                        transform: translateX(0px)
                        height: 50px
                        .navIcon
                            margin-left: 45px
                            margin-top: 20px
                            margin-left: 0px
                            margin-right: 20px
            #footerPhoto
                width: $small-footer-height
                img
                    position: absolute
                    left: 50%
                    top: 50%
                    transform: translateX(-63%) translateY(-50%)
                    height: $small-footer-height
                    width: auto
@media(max-width: 600px)
    #footer
        height: auto
        #mainFooter
            flex-direction: column
            #footerCopyrightsContainer
                margin-left: 30px
                margin-top: 330px
                margin-bottom: 20px
                #contactSocialsContainer
                    flex-direction: column
                    #meSocialsContainer
                        margin-top: 20px
                        margin-bottom: 30px
                        flex-direction: row
                        transform: translateX(0px)
                        height: 50px
                        .navIcon
                            margin-left: 45px
                            margin-top: 20px
                            margin-left: 0px
                            margin-right: 45px

            #footerPhoto
                margin: auto
                height: $footer-height
                order: 1
                img
                    position: relative
                    left: 0%
                    top: 0%
                    transform: translateX(0%) translateY(-80%)
                    height: $footer-height
                    width: auto

@media(max-width: 330px)
    #footer
        height: auto
        #mainFooter
            flex-direction: column
            #footerCopyrightsContainer
                margin-left: 30px
                margin-top: 0px
                margin-bottom: 20px
                #contactSocialsContainer
                    flex-direction: column
                    #meSocialsContainer
                        margin-top: 20px
                        margin-bottom: 30px
                        flex-direction: row
                        transform: translateX(0px)
                        height: 50px
                        .navIcon
                            margin-left: 45px
                            margin-top: 20px
                            margin-left: 0px
                            margin-right: 45px

            #footerPhoto
                display: none
                margin: auto
                height: $footer-height
                order: 1
                img
                    position: relative
                    left: 0%
                    top: 0%
                    transform: translateX(0%) translateY(0%)
                    height: $footer-height
                    width: auto
