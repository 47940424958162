@import "./sass-variables.sass"

#projects
    min-height: $hmain
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    position: relative
    margin-bottom: 150px
    z-index: 6
    text-align: center

    h1
        margin-bottom: 1rem
        margin-bottom: 51px

    h5
        margin-top: 0
        padding-top: 4rem

        color: $csecond

    p
        width: 380px
        margin-bottom: 50px

    button
        margin: 50px 0

        &:hover
            background: $csecond
            color: $clight

    #imgContainer
        display: grid
        grid-template-columns: repeat(4, 1fr)
        grid-template-rows: repeat(2, 1fr)
        grid-gap: 20px
        justify-content: center
        width: 1100px
        height: 1500px

        .img1
            grid-column: span 4
            grid-row: span 1

        .img2
            grid-column: span 2
            grid-row: span 1

        .img3
            grid-column: span 2
            grid-row: span 1

        .img4
            grid-column: span 4
            grid-row: span 1

    #nextSkills
        position: absolute
        bottom: -330px
        z-index: 1
        h1
            padding: 0
            margin-bottom: 51px
            text-align: center
        #nextSkillsList
            list-style: none
            display: flex
            width: 760px
            justify-content: space-between
            padding: 0
            li
                width: 190px
                height: 190px
                background: $lin6
                display: flex
                position: relative
                span
                    margin: auto
                    color: $clight
                    font-size: 36px
                    font-weight: bold
                div
                    position: absolute
                    font-size: 176px
                    color: $clight
                    opacity: 0.41
                    font-weight: bold
                    top: -30px
                    right: 150px
@media (max-width: 1200px)
    #projects
        #imgContainer
            margin: 0 auto
            display: grid
            grid-template-columns: repeat(1, 1fr)
            grid-template-rows: repeat(1, 1fr)
            grid-gap: 20px
            width: 60vw
            height: auto
            .img1
                grid-column: span 1
                grid-row: span 1
                height: 60vw

            .img2
                grid-column: span 1
                grid-row: span 1
                height: 60vw

            .img3
                grid-column: span 1
                grid-row: span 1
                height: 60vw
            .img4
                grid-column: span 1
                grid-row: span 1
                height: 60vw

@media (max-width: 900px)
    #projects
        #imgContainer
            margin: 0 auto
            display: grid
            grid-template-columns: repeat(1, 1fr)
            grid-template-rows: repeat(1, 1fr)
            grid-gap: 20px
            width: 550px
            height: auto

            .img1
                grid-column: span 1
                grid-row: span 1
                height: 60vw
                min-height: 400px

            .img2
                grid-column: span 1
                grid-row: span 1
                height: 60vw
                min-height: 400px

            .img3
                grid-column: span 1
                grid-row: span 1
                height: 60vw
                min-height: 400px

            .img4
                grid-column: span 1
                grid-row: span 1
                height: 60vw
                min-height: 400px

        #nextSkills
            position: absolute
            bottom: -520px
            z-index: 1
            h1
                margin-bottom: 20px
            #nextSkillsList
                list-style: none
                display: flex
                flex-wrap: wrap
                width: 600px
                justify-content: center
                padding: 0
                margin: 0
                li
                    margin: 20px 50px
                    width: 160px
                    height: 160px
                    background: $lin6
                    display: flex
                    position: relative
                    span
                        margin: auto
                        color: $clight
                        font-size: 36px
                        font-weight: bold
                    div
                        position: absolute
                        font-size: 140px
                        color: $clight
                        opacity: 0.41
                        font-weight: bold
                        top: -30px
                        right: 135px

@media (max-width: 600px)
    #projects
        p
            width: 300px
            text-align: center
        #imgContainer
            margin: 0 auto
            display: grid
            grid-template-columns: repeat(1, 1fr)
            grid-template-rows: repeat(1, 1fr)
            grid-gap: 20px
            width: 280px
            height: auto

            .img1
                grid-column: span 1
                grid-row: span 1
                height: 280px

            .img2
                grid-column: span 1
                grid-row: span 1
                height: 280px

            .img3
                grid-column: span 1
                grid-row: span 1
                height: 280px
            .img4
                grid-column: span 1
                grid-row: span 1
                height: 280px

        #nextSkills
            position: absolute
            bottom: -430px
            z-index: 1
            h1
                margin-bottom: 20px
            #nextSkillsList
                list-style: none
                display: flex
                flex-wrap: wrap
                width: 350px
                justify-content: center
                padding: 0
                margin: 0
                li
                    margin: 20px 25px
                    width: 120px
                    height: 120px
                    background: $lin6
                    display: flex
                    position: relative
                    span
                        margin: auto
                        color: $clight
                        font-size: 30px
                        font-weight: bold
                    div
                        position: absolute
                        font-size: 100px
                        color: $clight
                        opacity: 0.41
                        font-weight: bold
                        top: -10px
                        right: 105px

@media (max-width: 300px)
    #projects
        #nextSkills
            position: absolute
            bottom: -600px
            z-index: 1
            h1
                margin-bottom: 20px
            #nextSkillsList
                list-style: none
                display: flex
                flex-wrap: wrap
                width: 250px
                justify-content: center
                padding: 0
                margin: 0
                li
                    margin: 20px 25px
                    width: 120px
                    height: 120px
                    background: $lin6
                    display: flex
                    position: relative
                    span
                        margin: auto
                        color: $clight
                        font-size: 30px
                        font-weight: bold
                    div
                        position: absolute
                        font-size: 100px
                        color: $clight
                        opacity: 0.41
                        font-weight: bold
                        top: -10px
                        right: 105px
