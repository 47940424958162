@import "./sass-variables.sass"

.homeArrow
    border: 5px solid #333333
    padding: 10px
    position: fixed
    z-index: 100
    right: 30px
    bottom: 60px
    border-radius: 50%
    text-decoration: none
    color: $clight
    border: 2px solid $clight
    background: $csecond
    width: 22px
    height: 22px
    display: grid
    place-items: center

    &:hover
        color: $cmain
        border: 2px solid $cmain
        background: $cfour

