@import "./sass-variables.sass"

.skill
    text-align: center
    margin-bottom: 30px
    margin-left: 5px
    margin-right: 5px
    transition: transform 1s
    .skillImg
        width: 120px
        height: 120px
        background: $clight
        border-radius: 24px
        margin: 0 10px 15px 10px
        display: flex
        img
            width: 96px
            height: 85px
            margin: auto
    p
        width: 100px
        text-align: center
.left
    transform: translateX(-1000px)

.right
    transform: translateX(1000px)