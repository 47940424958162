@import "./sass-variables.sass"

#aboutMe
    background-color: $clight
    width: 100%
    color: $csecond
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    text-align: center
    #amInfo
        display: flex
        flex-direction: column
        align-items: center
        width: 80%
        margin: auto
        h5
            padding-top: 4rem
            color: $csecond
            margin-top: 0px
            display: inline-block
        h1
            margin-bottom: 51px
        p
        #aboutList
            width: 490px
            text-align: left
            margin: auto
            list-style: none
            padding: 0px 20px 300px 20px
            li
                margin-top: 40px
                display: flex
                font-size: 50px

                p
                    width: 440
                    margin: 0 10px

                span
                    min-width: 10px
                    height: 10px
                    background-color: $cthird
                    margin-right: 10px
                    margin-top: 7px
                    border-radius: 50%
    #experienceContainer
        position: absolute
        z-index: 5
        left: 50%
        transform: translate(-50%)
        bottom: -300px
        #experience
            width: 700px
            height: 387px
            background: $lin4
            margin: auto
            position: relative
            margin-top: 150px
            display: flex
            flex-direction: column
            align-items: center
            h1
                color: $csecond
                position: absolute
                top: -50px
                left: -50px
                text-align: left
                span
                    color: $clight
                #my
                    color: $csecond
            #experienceList
                width: 490px
                text-align: left
                margin: auto
                list-style: none
                color: $clight
                padding: 60px 20px 30px 20px
                li
                    padding-top: 30px
                    display: flex
                    font-size: 16px

                    p
                        width: 440
                        margin: 0 10px

                    span
                        min-width: 10px
                        height: 10px
                        background-color: $cthird
                        margin-right: 10px
                        margin-top: 7px
                        border-radius: 50%

            button
                background: rgba(0,0,0,0)
                border: 1px solid $clight
                color: $clight
                font-size: 16px
                margin-top: 0px
                &:hover
                    background: $clight
                    color: #A14DD9
            #experienceImg
                position: absolute
                left: 65%
                top: -70%

@media(max-width: 900px)
    #aboutMe
        #amInfo
            #aboutList
                width: 400px
                padding-bottom: 200px
                li
                    p
                        width: 390px
                        margin: 0px 10px
        #experienceContainer
            #experience
                width: 400px
                padding-bottom: 20px
                #experienceList
                    width: 350px
                    padding: 40px 20px 30px 20px
                #experienceImg
                    position: absolute
                    left: 65%
                    top: -70%
                    transform: scale(0.6)

@media(max-width: 600px)
    #aboutMe
        #amInfo
            #aboutList
                width: 350px
                padding: 0 0 270px 0
                li
                    p
                        width: 250px
                        margin: 0px 10px
        #experienceContainer
            #experience
                width: 100vw
                h1
                    color: $csecond
                    position: absolute
                    top: -35px
                    left: 0px
                    text-align: left
                    padding-left: 20px
                    span
                        color: $clight
                    #my
                        color: $clight
                #experienceImg
                    position: absolute
                    left: 40%
                    top: -65%
                    transform: scale(0.4)

@media(max-width: 330px)
    #aboutMe
        #amInfo
            #aboutList
                width: 350px
                padding: 0 0 270px 0
                li
                    p
                        width: 210px
                        margin: 0px 10px
        #experienceContainer
            #experience
                width: 100vw
                height: 450px
                h1
                    color: $csecond
                    position: absolute
                    top: -35px
                    left: 0px
                    text-align: left
                    padding-left: 20px
                    span
                        color: $clight
                    #my
                        color: $clight
                p
                    width: 210px
                #experienceImg
                    position: absolute
                    left: 40%
                    top: -57%
                    transform: scale(0.4)
