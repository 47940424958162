@import "./sass-variables.sass"

#skills
    width: 100%
    display: flex
    flex-direction: column
    padding-top: 250px
    padding-bottom: 3rem
    background: $lin3
    background-size: $bs3
    background-position: center
    z-index: 4
    position: relative
    align-items: center
    #skillsTitles
        width: 494px
        text-align: left
        margin-top: 4rem
        text-align: center

        h1
            color: $cthird
    #skillsContainer
        display: flex
        flex-wrap: wrap
        justify-content: center
        width: 1200px
        padding: 8px 0px 50px 0px
        font-weight: 700

@media(max-width: 1300px)
    #skills
        #skillsContainer
            width: 600px
            flex-wrap: wrap

@media(max-width: 700px)
    #skills
        #skillsContainer
            width: 300px
            flex-wrap: wrap

@media(max-width: 330px)
    #skills
        #skillsTitles
            width: 250px
        #skillsContainer
            width: 300px
            flex-wrap: wrap
