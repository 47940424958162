@import "./styles/sass-variables.sass"
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap')

*
  position: relative

html
  scroll-behavior: smooth
  

body
  margin: 0
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: $csecond
  font-family: $fone
  box-sizing: border-box
  font-size: 16px

button
  width: 350px
  height: 50px
  border-radius: 9px
  border: 0 solid rgba(0,0,0,0)
  background: #F2F7FC
  color: $csecond
  font-size: 16px
  font-weight: 600
  cursor: pointer
  margin: 1.5rem
  outline: none
  transition: 0.2s

h5
  color: $cthird
  margin-top: 0px
  text-transform: uppercase
  font-weight: 400
  font-size: 16px
  margin-bottom: 46px
  padding-top: 64px

h1
  max-width: 600px
  margin: auto
  margin-bottom: 51px
  padding-top: 0px
  font-size: 36px


p
  max-width: 600px
  margin: auto
  font-size: 16px

.imageDiv
  max-width: 100%
  max-height: 100%
  object-fit: cover
  img
    width: 100%
    height: 100%
    object-fit: cover

#mainWrapper
  position: relative
  overflow-x: hidden

@media(max-width: 600px)
  body
    font-size: 14px
  h1
    font-size: 24px
  p
    font-size: 14px
  button
    width: 250px
