@import "./sass-variables.sass"

.imageDiv
    position: relative
    // background: $cthird
    overflow: hidden
    .imageContainer
        position: absolute
        width: 100%
        height: 100%
        background: rgba(67,5,200, 0.8)
        top: 0
        left: 0
        display: flex
        opacity: 0.001
        transition: 0.2s

        .imageText
            position: absolute
            bottom: -50px
            left: 20px
            opacity: 0
            margin-bottom: 20px
            color: $clight
            text-align: left
            transition: 0.3s
            z-index: 6
            h2
                margin: 0
                padding: 0
                font-size: 70px
                font-weight: 900
                text-transform: uppercase
            h4
                margin: 0
                padding: 0
                font-size: 20px
                font-weight: 300
        .plusIcon
            margin: auto
            color: $clight
            font-size: 120px
            font-weight: 900
            cursor: pointer
            z-index: 100000
            &:hover
                color: $cthird
    &:hover
        img
            transition: 0.5s
            transform: scale(1.4)
        .imageContainer
            opacity: 1
            .imageText
                bottom: 20px
                opacity: 1

@media(max-width: 900px)
    .imageDiv
        .imageContainer
            .imageText
                margin-bottom: 0px

@media(max-width: 600px)
    .imageDiv
        .imageContainer
            .imageText
                margin-bottom: 0px
                h2
                    margin: 0
                    padding: 0
                    font-size: 50px
                    font-weight: 900
                    text-transform: uppercase
                h4
                    margin: 0
                    padding: 0
                    font-size: 16px
                    font-weight: 300
