//colors
$cmain: lavender
$csecond: #5D81F8
$cthird: #F8A412
$cfour: #35357E
$clight: #FFFFFF
$cfooter: #aaaaaa

//fonts
$fone: 'Manrope', sans-serif
$ftwo: 'Signika', sans-serif
$fthird: 'Titillium Web', sans-serif

//sectionHeight
$hmain: 600px

//maxwidth of sections
$maxWidth: 1200px

//linears
$lin1: repeating-linear-gradient(90deg, rgba(255,255,255,0.5), rgba(255,255,255,0.5) 1px, transparent 0px, transparent 130px), linear-gradient( #5D81F8, #B5EDFF)
$lin2: repeating-linear-gradient(90deg, rgba(248, 164, 18, 0.5), rgba(248, 164, 18, 0.5) 1px, transparent 0px, transparent 95px), linear-gradient( #5D81F8, #4000A1)
$lin3: repeating-linear-gradient(90deg, rgba(#5D81F8, 0.5), rgba(#5D81F8, 0.5) 1px, transparent 0px, transparent 150px),linear-gradient(#F2F7FC, #FFE0B2)
$lin4: linear-gradient(#5D81F8, #A14DD9)
$lin5: repeating-linear-gradient(90deg, rgba($clight, 0.5), rgba($clight, 0.5) 1px, transparent 0px, transparent 95px),linear-gradient(#FFE0B2, #E5F2FF)
$lin6: linear-gradient(#7B69E9,#C247D5)

//backgrounds size
$bs1: 3800px 100%
$bs2: 3750px 100%
$bs3: 3600px 100%
