@import "./sass-variables.sass"

#next
    width: 100%
    display: flex
    flex-direction: column
    padding-top: 250px
    background: $lin5
    background-size: $bs1
    background-position: center
    z-index: 0
    position: relative
    align-items: center
    #nextDeveloper
        width: 760px
        h1
            text-align: left
            margin-left: 0px
        #nextDeveloperList
            width: 490px
            text-align: left
            margin: auto
            list-style: none
            padding-bottom: 100px
            li
                margin-top: 30px
                display: flex
                font-size: 50px

                p
                    width: 440
                    margin: 0 10px

                span
                    min-width: 10px
                    height: 10px
                    background-color: $cthird
                    margin-right: 10px
                    margin-top: 7px
                    border-radius: 50%

@media(max-width: 900px)
    #next
        width: 100%
        display: flex
        flex-direction: column
        padding-top: 450px
        background: $lin5
        background-size: $bs1
        background-position: center
        z-index: 0
        position: relative
        align-items: center
        #nextDeveloper
            width: 550px
            h1
                text-align: left
                margin-left: 0px
                padding: 0 30px
            #nextDeveloperList
                width: 450px
                text-align: left
                margin: auto
                list-style: none
                padding-bottom: 100px
                li
                    margin-top: 35px
                    display: flex
                    font-size: 50px

                    p
                        width: 440

                    span
                        min-width: 10px
                        height: 10px
                        background-color: $cthird
                        margin-right: 10px
                        margin-top: 7px
                        border-radius: 50%

@media(max-width: 600px)
    #next
        padding-top: 350px
        #nextDeveloper
            width: 300px
            h1
                padding: 0
            #nextDeveloperList
                padding: 0 0 100px 0
                width: 350px
                margin-right: 40px
                p
                    width: 250px

@media(max-width: 300px)
    #next
        padding-top: 500px
        #nextDeveloper
            width: 250px
            h1
                padding: 0
                font-size: 20px
            #nextDeveloperList
                padding: 0 0 50px 0
                width: 250px
